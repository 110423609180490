import * as React from 'react';
import Layout from '../components/layout';
import Hero from '../components/hero';
import { lazy, Suspense } from 'react';
import { SEO } from '../components/seo';

//Lazy Loadings
const HeroContent = lazy(() => import('../components/HeroContent'));
const ServiceCardSection = lazy(
  () => import('../components/serviceCardSection'),
);
const FaceCardSection = lazy(() => import('../components/faceCardSection'));
const ContactSection = lazy(() => import('../components/contactSection'));
const TechStackSection = lazy(() => import('../components/techStackSection'));
const SwiperIcons = lazy(() => import('../components/SwiperIcons'));

const hero = (
  <Hero>
    <Suspense fallback={<div>loading</div>}>
      <HeroContent />
    </Suspense>
  </Hero>
);

const IndexPage = ({ }: any) => {
  return (
    <>
      <SEO />
      <Suspense fallback={<div>loading</div>}>
        <Layout pageTitle="Home Page" hero={hero}>
          <Suspense fallback={<div>loading</div>}>
            <ServiceCardSection />
          </Suspense>
          <TechStackSection>
            <SwiperIcons />
          </TechStackSection>
          <ContactSection />
        </Layout>
      </Suspense>
    </>
  );
};

export default IndexPage;
