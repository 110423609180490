import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import HeroPixelated from '../images/heroImg_900px_60q_pixelated.webp';
import Hero600 from '../images/heroImg_600x915.webp';
import Hero900 from '../images/heroImg_900px_60q.webp';
import Hero1200 from '../images/heroImg_1200px_60q.webp';
import Hero1440 from '../images/heroImg_1440px_60q.webp';

const style: SxStyle = {
  componentHeroBgGridContainer: {
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'end',
    position: 'relative',
    marginTop: 8,
    marginLeft: 0,
    minHeight: '50vh',
    overflow: 'hidden',
    '&::before': {
      margin: '-65px',
      width: '120%',
      height: '120%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: { xs: 'none', lg: `url(${Hero1440})` },
      filter: 'blur(28px)',
      bottom: 0,
      content: '""',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  heroContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    maxWidth: '1440px',
    minHeight: '50vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: {
      xs: `url(${Hero600})`,
      sm: `url(${Hero600})`,
      md: `url(${Hero900})`,
      lg: `url(${Hero1200})`,
      xl: `url(${Hero1440})`,
    },
    zIndex: '1',
  },
  heroWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    maxWidth: '1440px',
    width: 'inherit',
    minHeight: '50vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: { xs: `url(${HeroPixelated})` },
    zIndex: '20',
  },
  heroGridContainer: {
    p: { xs: '10px 30px 10px 30px', sm: '10px 40px 10px 40px' },
    width: '1440px',
    zIndex: 2,
    background:
      'linear-gradient(0deg, rgba(0,0,0,0.4920343137254902) 0%, rgba(195,193,184,0) 50%)',
  },
};

export default function Hero({ children }: HeroProps) {
  return (
    <>
      <Grid
        container
        spacing={2}
        xs={12}
        sx={style.componentHeroBgGridContainer}
      >
        <Box sx={style.heroWrapper}>
          <Container disableGutters maxWidth={false} sx={style.heroContainer}>
            <Grid container xs={12} sx={style.heroGridContainer}>
              <Grid item xs={6}>
                {children}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Grid>
    </>
  );
}
